<template>
  <b-card no-body>
    <b-card-header>
      <div>
        <b-card-title class="font-weight-bolder">
          {{ $t("message.chart.contract") }}
        </b-card-title>
        <b-card-sub-title class="mt-25">
          {{ $t("message.chart.contractDesc") }}
        </b-card-sub-title>
      </div>

      <h4 style="color: #6a85b1">
        <div style="color: #6ab187">
          {{
            $t("message.chart.totalValueOpen") +
            ": " +
            $n(sumValues(allOpenValues), "currency")
          }}
        </div>
        {{
          $t("message.chart.totalValueClosed") +
          ": " +
          $n(Object.values(totalClosedValue), "currency")
        }}
      </h4>
    </b-card-header>

    <b-card-body>
      <vue-apex-charts
        type="bar"
        :height="chart.height"
        :options="chartOptions"
        :series="series"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCardSubTitle,
  BCardTitle,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import flatPickr from "vue-flatpickr-component";
import axios from "@axios";
import URL_API from "@core/utils/env";
import getHeader from "@core/utils/auth";
import moment from "moment";
import store from "@/store";

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    flatPickr,
    BCardBody,
    BCardSubTitle,
    BCardTitle,
  },

  data() {
    return {
      chart: {},
      series: [],
      chartOptions: {},

      chartLabels: [],
      chartValues: [],

      chartTimestamp: "",

      totalClosedValue: "",
      allOpenValues: "",

      data: [
        { name: "Notas ficais", data: [25002, 10450, 2020, 7100, 3000, 6500] },
        {
          categories: [
            "Volume total",
            "Contrato 1",
            "Contrato 2",
            "Contrato 3",
            "Contrato 4",
            "Contrato 5",
          ],
        },
      ],

      userData: store.state.user.userData,
    };
  },

  async created() {
    await this.loadChartData();
  },

  watch: {
    "$i18n.locale": function (newVal, oldVal) {
      this.loadChartData();
    },
  },

  methods: {
    sumValues(value) {
      var totalValue = 0;
      for (var i = 0; i < Object.values(value).length; i++) {
        totalValue = totalValue + Object.values(value)[i];
      }

      return totalValue;
    },

    async loadChartData() {
      var vm = this;

      await axios
        .get(`${URL_API}chart/contracts/all`, {
          headers: getHeader(this.userData),
        })
        .then((response) => {
          var string = response.data.chartInfo;

          if (string != null) {
            var data = JSON.parse(string); // Json to object

            this.totalClosedValue = data[0];
            this.allOpenValues = data[1];

            this.chartLabels = Object.keys(data[1]);
            this.chartValues = Object.values(data[1]);

            this.chartTimestamp = response.data.timestamp;
          }
        });

      this.chart = {
        height: vm.getAutoHeight(vm.chartLabels.length),
      };

      this.series = [
        {
          name: vm.getColLabel("totalValueOpen"),
          data: this.chartValues,
        },
      ];
      //this.series[0].data.sort((a, b) => b-a); // Ordenar lista

      this.chartOptions = {
        colors: [
          function ({ value, seriesIndex, dataPointIndex, w }) {
            return "#6ab187";
          },
        ],

        title: {
          text: vm.localeDate(this.chartTimestamp),
          align: "right",
          margin: 10,
          offsetX: 0,
          offsetY: 25,
          floating: false,
          style: {
            fontSize: "12px",
            fontWeight: "normal",
          },
        },

        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: "100%",
          },
        },

        dataLabels: {
          enabled: true,
          enabledOnSeries: undefined,
          formatter: (val) => {
            return this.currencyValue(val);
          },
          textAnchor: "middle",
          distributed: false,
          offsetX: 0,
          offsetY: 0,
          background: {
            enabled: true,
            foreColor: "#fff",
            borderWidth: 0,
          },
        },

        stroke: {
          width: 1,
          colors: ["#fff"],
        },

        grid: {
          xaxis: {
            lines: {
              show: false,
            },
          },
        },

        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 0.3,
            opacityFrom: 0.8,
            opacityTo: 0.9,
            stops: [0, 50, 100],
          },
        },

        tooltip: {
          shared: true,
          intersect: false,
          x: {
            show: true,
          },
          y: {
            formatter: (value) => {
              return this.currencyValue(value);
            },
          },
        },

        yaxis: {
          show: true,
        },

        xaxis: {
          categories: this.chartLabels,
          labels: {
            show: true,
            formatter: (value) => {
              return this.currencyValue(value);
            },
          },
        },
      };
    },

    localeDate(value) {
      var newFormat = moment(value, "ddd MMM DD HH:mm:ss z yyyy").format(
        "MM-DD-YYYY"
      );
      var newHour = moment(value, "ddd MMM DD HH:mm:ss z yyyy").format(
        "HH:mm:ss"
      );

      if (newFormat != "Invalid date" && newFormat != null) {
        return (
          this.$i18n.d(
            new Date(newFormat.split("-")),
            "short",
            this.$i18n.locale
          ) +
          " - " +
          newHour
        );
      }
      return this.$i18n.t("message." + "noDate");
    },

    getColLabel(colName) {
      return this.$i18n.t("message.chart." + colName);
    },

    getAutoHeight(value) {
      if (value === 0) {
        return 600;
      } else if (value === 1) {
        return 2 * 100;
      } else {
        return value * 120;
      }
    },

    currencyValue(value) {
      return this.$i18n.n(value, "currency");
    },
  },
};
</script>
